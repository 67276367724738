.edit-product-details {
    font-family: 'Times New Roman', Times, serif;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.edit-product-details p {
    margin: 0;
    padding: 10px;
    cursor: pointer;
}

.edit-product-details p:hover {
    font-weight: bolder;
    text-decoration: underline;
}

.edit-product-details h3 {
    text-align: center;
    padding: 20px 10px 10px 10px;
    color: #02083d;
    margin-bottom: 20px;
}

.edit-product-details .button-group {
    display: flex;
    
}

.edit-product-details .button-group button {
    padding: 5px 10px 5px 10px;
    margin: auto 10px 15px 10px;
    border: 1px solid #02083d;
    border-radius: 5px;
    background-color: transparent;
    color: #02083d;
}

.edit-product-details .button-group .edit-details-button {
    margin-right: auto; 
}

.edit-product-details .button-group .cancel-button {
    margin-left: auto;
}
 
.edit-product-details button:hover {
    background-color: #02083d;
    color: #e9d24f;
}