.new-product-details {
    font-family: 'Times New Roman', Times, serif;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.new-product-details p {
    margin: 0;
    padding: 10px;
    cursor: pointer;
}

.new-product-details p:hover {
    font-weight: bolder;
    text-decoration: underline;
}

.new-product-details h3 {
    text-align: center;
    padding: 20px 10px 10px 10px;
    color: #02083d;
    margin-bottom: 20px;
}

.new-product-details .add-new-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 10px 5px 10px;
    margin-bottom: 15px;
    border: 1px solid #02083d;
    border-radius: 5px;
    background-color: transparent;
    color: #02083d;
}

.new-product-details .add-new-button:hover {
    background-color: #02083d;
    color: #e9d24f;
}

.image-upload-container {
    position: relative;
    width: 100px;
    height: 100px; 
    border: 1px solid #ced4da; /* Add a border for visibility */
    border-radius: 5px;
    overflow: hidden;
}

.image-preview {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.upload-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}

.remove-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    font-weight: bolder;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hidden {
    display: none;
}

.form-label {
    font-weight: bold;
}
