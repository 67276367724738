.admin-portal-gateway {
    background-image: url(../images/home1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    height: 100vh;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-portal-gateway button {
    width: 15%;
    padding: 15px 5px 15px 5px;
    margin: 20px;
    text-align: center;
    font-size: large;
    border-radius: 10px;
    color: antiquewhite;
    background-color: rgb(0, 0, 119);
    border-color: rgb(1, 1, 112);
    cursor: pointer;
}

.admin-portal-gateway button:hover {
    background-color: rgb(1, 1, 77);
    border-color: rgb(1, 1, 66);
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .admin-portal-gateway button {
        width: 25%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .admin-portal-gateway button {
        width: 30%;
    }
}

@media (max-width: 575.98px) {
    .admin-portal-gateway {
        flex-direction: column;
    }
    .admin-portal-gateway button {
        width: 50%;
        font-size: medium;
    }
}

