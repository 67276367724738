.login-modal {
    display: inline-block;
    position: relative;
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.login-box {
    background-color: #a29bf8;
    border-radius: 8px;
    width: 400px;
    padding: 10px;
    margin: 10px;
}

.login-box .close {
    background-color: red;
    color: white;
    border-radius: 2px;
    width: 30px;
    padding-bottom: 5px;
    cursor: pointer;
}

.login-content {
    padding: 10px 30px 20px 30px;
    font-family: 'Times New Roman', Times, serif;
    color:#080241;
}

.login-content h3 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.login-content button {
    border: rgb(0, 0, 119);
    border-radius: 5px;
    background-color: rgb(0, 0, 119);
    padding: 4px 15px 4px 15px;
    margin-top: 10px;
}

.login-content button:hover {
    background-color: rgb(1, 1, 77);
}

.login-content .btn-login {
    color: #c3bef7;
    font-family: 'Times New Roman', Times, serif;
    font-size: larger;
}

.link-login {
    font-size: larger;
  }

.link-login:hover {
    cursor: pointer;
    text-decoration: underline;
}

.login-error {
    color: rgb(250, 51, 51);
}

.log-password {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .log-password input {
    flex: 1;
  }
  
  .log-password button{
    background-color: transparent;
    color: #080241;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 5px; /* Adjust the right position as needed */
    top: 25%;
    transform: translateY(-50%);
    
  }