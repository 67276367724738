.container-fluid {
    padding: 0px !important;
    display: flex;
    flex-direction: column; 
    min-height: 100vh;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.main {
    flex-grow: 1;
    background-color:rgb(248, 250, 252);
}

.row {
    margin: 0;
}