.navbar-dark {
    background-color: #030a4d;
    padding: 10px;
}

.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logo-link:hover {
    text-decoration: none;
}

.logo-link h4 {
    color: #e9d24f;
    padding-left: 10px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.logo-link h4:hover {
    font-weight: bolder;
}

.navbar-dark .navbar-link {
    color: #e9d24f;
    font-size: large;
    padding: 5px 10px 5px 10px;
}

.navbar-dark .navbar-link:hover {
    font-weight: bolder;
    font-size: 1.2em;
}

.navbar-link.active-link {
    font-weight: bolder;
    color: #eed025;
}

.navbar-dark .btn-logout {
    background-color: rgb(158, 1, 1);
    border: rgb(158, 1, 1);
    border-radius: 5px;
    color: antiquewhite;
}

.navbar-dark .btn-logout:hover {
    background-color: rgb(90, 1, 1);
}