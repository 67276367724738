.add-new-product {
    font-family: 'Times New Roman', Times, serif;
    display: flex;
}

.add-new-product .add-new-button {
    align-items: flex-start;
    margin: 20px 50px 5px 50px;
    padding: 5px 10px 5px 10px;
    background-color: transparent;
    border-color: rgb(0, 37, 0);
    border-radius: 5px;
    color: rgb(0, 37, 0);
}

.add-new-product .add-new-button:hover {
    background-color: rgb(0, 37, 0);
    color: #e9d24f;
} 

.products {
    font-family: 'Times New Roman', Times, serif;
}

.products h3 {
    padding: 20px 10px 10px 10px;
    color: #02083d;
}

.products .image-group {
    margin: 10px 20px 10px 30px;
}

.products .image-group img {
    height: 200px;
}

.products .image-group h5{
    padding-top: 20px;
}

.products .image-group p {
    margin-bottom: 10px;
}

.products .image-group .sale {
    color: white;
    background-color: green;
    padding: 3px;
    border-radius: 5px;
}

.products .image-group button {
    margin: 5px;
}

.products .image-group .edit-button {
    border-color: #02083d;
    background-color: transparent;
    color: #02083d;
    padding: 5px 15px 5px 15px;
    border-radius: 6px;
}

.products .image-group .edit-button:hover {
    background-color: #02083d;
    color: #e9d24f;
}

.products .image-group .remove-button {
    border-color: #bd0000;
    background-color: transparent;
    color: #bd0000;
    padding: 5px 15px 5px 15px;
    border-radius: 6px;
}

.products .image-group .remove-button:hover {
    background-color: #bd0000;
    color: #e9d24f;
}

/*Styles for AddNewProductModal*/

/*
.add-new-modal {
    display: inline-block;
    position: relative;
}

.add-new-form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.add-new-box {
    background-color: rgb(237, 247, 237);
    border-radius: 8px;
    width: 400px;
    padding: 10px;
    margin: auto;
}

.add-new-box .close {
    background-color: red;
    color: white;
    border-radius: 2px;
    width: 30px;
    padding-bottom: 5px;
    cursor: pointer;
}

.add-new-details {
    padding: 10px 30px 20px 30px;
    font-family: 'Times New Roman', Times, serif;
    color:rgb(0, 70, 0);
}

.add-new-details h3 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.add-new-details .add-new-button {
    border: 2px solid rgb(0, 70, 0);
    border-radius: 5px;
    background-color: transparent;
    padding: 4px 15px 4px 15px;
    margin-top: 10px;
    color: rgb(0, 70, 0);
    display: block;
    margin: 0 auto;
}

.add-new-details .add-new-button:hover {
    background-color: rgb(0, 70, 0);
    color: white;
}
*/