.custom-footer {
    background-color: #030a4d;
    color: #cf964c;
    padding: 10px 0;
    text-align: center;
    bottom: 0;
}

.footer-link {
    color: #cf964c;
    text-decoration: none;
    padding: 0 10px 0 10px;
}

.footer-link:hover {
    color: #e28f23;
}

.custom-footer p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}
