.product-images {
    padding: 5px;
}

.product-images .large-img {
    padding: 10px;
}

.product-images .large-img img {
    max-width: 45vw;
    max-height: 100vh;
    width: auto;
    height: auto;
}

.product-images .small-img img {
    width: 70px;
    height: 70px;
    margin: 0 5px;
    cursor: pointer;
    border: 1px solid #ddd;
}

.product-images .small-img img.selected {
    border: 2px solid #007bff;
}

.product-details {
    color: #02083d;
    text-align: left;
}

.product-details h4 {
    padding-top: 20px;
    font-size: 30px;
}

.product-details hr {
    width: 75%;
}

.product-details .sale {
    color: white;
    background-color: green;
    padding: 5px;
    border-radius: 5px;
}

.view-all-products-button {
    background-color: transparent;
    border-radius: 5px;
    color: #02083d;
    border-color: #02083d;
    padding: 5px 15px 5px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.view-all-products-button:hover {
    background-color: #02083d;
    color: #e9d24f;
}